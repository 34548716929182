import React, { Component } from 'react';
//import logo from './images/logo.png';

class SplashScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        return (

                <div className="container-fluid d-flex align-items-center vh-100">
                    <div className="row d-flex justify-content-center vw-100">
                       <div className="content col-12 col col-sm-6 col-lg-3 text-center">
                           Supply Chain Scan
                       </div>
                   </div>
               </div>

        );
    }
}

export default SplashScreen;