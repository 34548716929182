import React, {Component} from 'react';

import SplashScreen from './SplashScreen';
import Home from './Home';
import Login from './Login';
import './App.css';

import Logo from './images/logo.png';

import { IoMdQrScanner } from "react-icons/io";

/*

 domain: 'http://localhost:8888',
 domain: 'https://qrscs.joch.dk',
 */

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domain: 'https://qrscs.joch.dk',
            companyId: '',
            companyName: '',
            initials: '',
            token: '',
            hostUrl: '',
            colors: {
                color_1: '#000',
                color_2: '#FFF',
                color_3: '#45c797'
            },
            backgroundColor: '#ebddfa',
            screen: 'splashscreen',
            code: ''

        };
    };

    componentDidMount() {

        this.splashscreenWait = setTimeout(function () {
            clearInterval(this.splashscreenWait);
            this.setup();
        }.bind(this), 1000);

    };

    setup() {

        const code = this.getParameterByName('code');
        if(code) {
            this.setState({ code: code });
        }

        document.body.style.backgroundColor = this.state.backgroundColor;
        this.setState({ screen: 'home'});

    }



    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    changeScreen(screen, prop) {

        if(prop === 'clear') {
            console.log('in');
            this.setState({ code: ''});
        }

        if(prop.hasOwnProperty('code')) {
            this.setState({ code: prop.code});
        }

        if(prop.hasOwnProperty('token')) {
            this.setState({ token: prop.token});
        }

        this.setState({ screen: screen });
    }

    updateCodeInHome = (code) => {

        this.setState({ code: code});

        if(this.state.screen !== 'home') {
            this.changeScreen('home', '');
        } else {
            // Call the updateStep function in the Home component
            this.refs.home.updateCode(code);
        }
    };

    render() {

      return (

          <div className="container-fluid d-flex align-items-center vh-100">
              <div className="row d-flex justify-content-center vh-100 vw-100">
                  <div id="page" className="content-wrapper page-home col-12 col col-sm-9 col-lg-6">

                      <div id="header" className="row">
                          <div className="col-12">
                              <img id="logo" src={Logo} alt="Logo" />
                          </div>
                      </div>

                          {this.state.screen === 'splashscreen' && (
                              <SplashScreen
                                  path                = "/"
                                  hostUrl             = {this.state.hostUrl}
                                  colors              = {this.state.colors}
                                  mainStyle           = {this.state.mainStyle}
                                  />
                          )}

                          {this.state.screen === 'login' && (
                              <Login
                                  path                = "/"
                                  hostUrl             = {this.state.hostUrl}
                                  domain              = {this.state.domain}
                                  colors              = {this.state.colors}
                                  mainStyle           = {this.state.mainStyle}
                                  changeScreen        = {this.changeScreen.bind(this)}
                                  />
                          )}



                          {this.state.screen === 'home' && (
                      <Home
                          ref                 = "home"
                          path                = "/"
                          hostUrl             = {this.state.hostUrl}
                          colors              = {this.state.colors}
                          domain              = {this.state.domain}
                          token               = {this.state.token}
                          code                = {this.state.code}
                          changeScreen        = {this.changeScreen.bind(this)}

                          />
                  )}


                      <div id="footer" className="row">
                          <div className="col-12 text-center">
                              <div id="scan-icon" onClick={() => this.updateCodeInHome('')}><IoMdQrScanner/></div>
                          </div>
                      </div>


                  </div>
              </div>
          </div>
          );
      };

}

export default App;
