import React, { Component } from 'react';
import axios from "axios";

import QrReader from 'modern-react-qr-reader'

import CodeTypeProject from './CodeTypeProject';
import CodeTypeChain from './CodeTypeChain';


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            delay: 300,
            resolution: 1000,
            result: '',
            qrRead: false,
            qrCodeImage: '',
            qrReader: 1,
            step: 1,
            codeType: 0,
            projectId: '',
            projectName: '',
            projectDesc: '',
            code: '',
            codes: {},
            chain: {},
            notes: {},
            files: {},
            message: 'Scan QR Code'
        };


        this.handleError        = this.handleError.bind(this);
        this.handleScan         = this.handleScan.bind(this);
        this.handleInputChange  = this.handleInputChange.bind(this)

        this.textareaRef        = React.createRef();

    }

    componentDidMount() {

        if(this.props.code !== '') {
            this.setState({ code: this.props.code });
            this.verifyCode(this.props.code);
        } else {
            console.log('data removed');
        }

    }

    updateCode = (code) => {
        this.setState({ code: code });
        this.setState({ step: 1 });
        this.setState({ qrReader: 1});
        this.setState({ message: 'Scan QR Code' });
    };

    handleInputChange(e) {
        const itemName = e.target.name;
        const itemValue = e.target.value;

        this.setState({ [itemName]: itemValue});
    }


    handleScan = code => {
        if (code) {
            this.setState({ code: code });
            this.verifyCode(code);
        }
    }

    handleError(err){
        console.error(err)
    }

    verifyCode(code) {

        this.setState({step: 2});
        this.setState({message: 'QR Code Verification'});

        var data            = {};
        data.code           = code;
        data.token          = this.props.token;

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=verifyQR',
            data: JSON.stringify(data)

        }).then((responseObject) => {

            if(responseObject.data.permission === 0) {

                this.props.changeScreen('login', responseObject.data);

            } else if(responseObject.data.permission === -1) {

                this.setState({step: 3});
                this.setState({message: 'Access to this Chain is restricted and not authorized for your account'});

            } else {

                const codeType = parseFloat(responseObject.data.code_type);
                this.setState({codeType: codeType});
                this.setState({message: ''});
                this.setState({projectId: responseObject.data.project_id});

                if(codeType === 1) {
                    // Project
                    this.setState({projectName: responseObject.data.project.name});

                    this.setState({projectDesc: responseObject.data.project.description});
                    this.setState({codes: responseObject.data.codes});
                    this.setState({step: 4});

                } else if(codeType === 2) {
                    // Chain
                    const chain = responseObject.data.chain;

                    if(chain) {
                        this.setState({chain: chain});
                        this.setState({notes: responseObject.data.notes});
                        this.setState({files: responseObject.data.files});
                        this.setState({step: 4});
                    } else {
                        console.log('done');
                        this.setState({step: 5});
                    }

                }



            }


        }).catch((error) => {

            console.log(error)

            this.setState({message: 'ERROR: QR Code not found'});

            this.wait = setTimeout(function () {

                clearInterval(this.wait);
                this.setState({note: ''});
                this.setState({step: 1});
                this.setState({message: ''});

            }.bind(this), 3000);

        });

    }

    updateChain(update) {

        this.setState({step: 0});
        this.setState({message: 'Sending your response'});

        var data            = {};
        data.project_id     = this.state.projectId;
        data.chain          = this.state.chain;
        data.update         = update;
        data.token          = this.props.token;

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=updateChain',
            data: JSON.stringify(data)

        }).then((responseObject) => {

            // console.log(responseObject);

            if(responseObject.data.chain_done) {

                this.setState({message: 'Thanks - The Chain is marked as completed'});

            } else {

                this.setState({message: 'Thanks - The note has been added'});

            }

            this.wait = setTimeout(function () {

                clearInterval(this.wait);

                this.verifyCode(this.state.code);
                this.setState({message: ''});

            }.bind(this), 2000);


        }).catch((error) => {

            console.log(error)

            this.setState({message: 'ERROR: QR Code not found'});

            this.wait = setTimeout(function () {

                clearInterval(this.wait);
                this.setState({note: ''});
                this.setState({step: 1});
                this.setState({message: ''});

            }.bind(this), 2000);

        });


    }



    scan(){
        this.setState({step: 1});
        this.setState({message: ''});
    }


    render() {

        const {colors,token} = this.props;

        const frontStyle    = {
            color: colors.color_1,
            textShadow:  '0px 4px 3px #00000030',
            whiteSpace: "pre-wrap"
        }

        const btnStyle = {
            color: colors.color_1,
            backgroundColor: colors.color_2,
            minWidth: '100px',
            cursor: 'pointer',
        }

        return (

            <div className="content pt-2">

                {this.state.message !== "" &&
                    <div id="message" className="mt-3 mb-3 text-center" style={frontStyle} dangerouslySetInnerHTML={{__html: this.state.message}}></div>
                }

               {this.state.qrReader === 1 && this.state.step === 1 &&
                   <div id="qr-wrapper" className="col-12 text-center">
                       <div id="qr-container">
                           <div id="qr-reader">

                               <QrReader
                                   delay            = {this.state.delay}
                                   resolution       = {this.state.resolution}
                                   onError          = {this.handleError}
                                   onScan           = {this.handleScan}
                                   style            = {{ width: '100%' }}
                                   showViewFinder   = {false}
                                   />

                               <p style={frontStyle}>{this.state.lead}</p>
                           </div>
                       </div>
                   </div>
               }

                {this.state.step === 2 &&
                    <div className="text-center">
                        Loading...
                    </div>
                }

                {this.state.step === 3 &&
                    <div className="form-group text-center mt-4 mb-4">
                        <button className="btn-medium btn-white" type="button" style={btnStyle}
                                onClick={() => this.scan()}>
                            OK
                        </button>
                    </div>
                }

                {this.state.step === 4 && this.state.codeType === 1 &&
                    <CodeTypeProject
                        domain      = {this.props.domain}
                        colors      = {colors}
                        codes       = {this.state.codes}
                        name        = {this.state.projectName}
                        desc        = {this.state.projectDesc}
                        />
                }

                {this.state.step === 4 && this.state.codeType === 2 &&
                    <CodeTypeChain
                        domain      = {this.props.domain}
                        colors      = {colors}
                        projectId   = {this.state.projectId}
                        chain       = {this.state.chain}
                        notes       = {this.state.notes}
                        files       = {this.state.files}
                        updateChain = {this.updateChain.bind(this)}
                        token       = {token}
                        />
                }

                {this.state.step === 5 &&
                <div className="text-center">
                    <div className="mb-3"><strong>Congratulations</strong></div>
                    <div>All tasks for this Chain is completed</div>
                </div>
                }


            <div className="section-bottom-wrapper mt-2"></div>
           </div>


        );
    }
}

export default Home;