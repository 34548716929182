import React, { Component } from 'react';
import axios from "axios";

import { IoMdLogIn } from 'react-icons/io';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            user: '',
            password: '',
            isValidUser: true,
            isValidPassword: true,

        };

        this.handleSubmit       = this.handleSubmit.bind(this);
        this.handleInputChange  = this.handleInputChange.bind(this);
    }

    componentDidMount() {

    }

    handleInputChange(e) {
        const itemName = e.target.name;
        const itemValue = e.target.value;

        this.setState({ [itemName]: itemValue});
    }

    handleSubmit(e) {

        e.preventDefault();

        this.setState({ message: ''});

        var errors = false;

        if (this.state.user.trim() === '') {
            errors = true;
            this.setState({ isValidUser: false });
        } else {
            this.setState({ isValidUser: true });
        }

        if (this.state.password.trim() === '') {
            errors = true;
            this.setState({ isValidPassword: false });
        } else {
            this.setState({ isValidPassword: true });
        }


        if(!errors) {

            var data = {};

            data.user = this.state.user;
            data.password = this.state.password;

            axios({
                method: 'post',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                url: this.props.domain + '/core/connect.php?action=signIn',
                data: JSON.stringify(data)

            }).then((responseObject) => {

                console.log(responseObject.data);

                if(responseObject.data.token) {
                    this.props.changeScreen('home', responseObject.data);
                } else {
                    this.setState({ message: 'User not found!'});
                }


            }).catch((error) => {

                console.log(error)

            });
        } else {
            this.setState({ message: 'Please fill in the required fields' });

        }

    }

    resetPassword(){
        this.props.changeScreen('resetpassword', '');
    }

    render() {

        const {colors} = this.props;

        const frontStyle    = {
            color: colors.color_1,
            textShadow:  '0px 4px 3px #00000030',
            whiteSpace: "pre-wrap"
        }

        const btnStyle = {
            color: colors.color_1,
            backgroundColor: colors.color_2,
            cursor: 'pointer',
        }

        return (

                <form onSubmit={this.handleSubmit} className="form-post-lead">

                    <div className="mt-3 mb-3" style={frontStyle}><strong>Login</strong></div>

                    <div className="mb-3" style={frontStyle}>You need to have permission to access this QR Code.</div>

                    {this.state.message !== "" &&
                        <div id="message" className="mb-3" style={frontStyle} dangerouslySetInnerHTML={{__html: this.state.message}}></div>
                    }

                    <div style={frontStyle}>User Name</div>
                    <div className="mt-1">
                        <input
                            className       = {"form-control pl-3"}
                            type            = "text"
                            id              = "user"
                            name            = "user"
                            placeholder     = "Your User Name"
                            defaultValue    = ""
                            onChange        = {this.handleInputChange}
                            style           = {{ borderColor: this.state.isValidUser ? '' : 'red' }}
                            />
                    </div>

                    <div className="mt-3" style={frontStyle}>Password</div>
                    <div className="mt-1">
                        <input
                            className           = {"form-control pl-3"}
                            type                = "password"
                            id                  = "password"
                            name                = "password"
                            placeholder         = "Your Password"
                            defaultValue        = ""
                            onChange            = {this.handleInputChange}
                            style               = {{ borderColor: this.state.isValidPassword ? '' : 'red' }}
                        />
                    </div>

                    <div className="form-group text-right mt-4 mb-4">
                        <button className="btn-medium btn-white" type="submit" style={btnStyle}>
                            Sign In <IoMdLogIn/>
                        </button>
                    </div>





                </form>


        );
    }
}

export default Login;