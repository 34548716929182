import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';
import QRCode from "react-qr-code";

class PrintableContent extends Component {
    render() {
        const { domain, codes } = this.props;

        return (
            <div>
                {codes.map((item, index) => (
                    <div id={'qr_' + index} className="row-code" key={index}>
                        <div className="name mb-2"><strong>{item.name}</strong></div>

                        <div style={{ height: 'auto', margin: '0 auto', width: '100%' }}>
                            <QRCode
                                size={256}
                                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                                value={domain + '/?code=' + item.code}
                                viewBox={`0 0 256 256`}
                                />
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

class CodeTypeProject extends Component {

    printableRefs = [];



    render() {

        const { domain,name,desc,codes,colors } = this.props;

        const btnStyle = {
            color: colors.color_2,
            backgroundColor: colors.color_3,
            cursor: 'pointer',
        }

        return (
            <div>
                <div id="description" className="wrapper">
                    <div className="title">{name}</div>
                    <div className="content">
                        <p>{desc}</p>
                    </div>

                    {codes.map((item, index) => (
                        <div className="part" key={index}>
                            <PrintableContent
                                domain={domain}
                                codes={[item]}
                                ref={(el) => (this.printableRefs[index] = el)}
                                />
                            <div className="text-center mt-2 mb-3">
                            <ReactToPrint
                                trigger={() => <button className="btn-medium mt-2 mb-2" style={btnStyle}>Print QR-Code for {item.name}</button>}
                                content={() => this.printableRefs[index]}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default CodeTypeProject;
