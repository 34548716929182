import React, {Component} from 'react';
import axios from "axios";
import Modal from 'react-modal';

import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

import { IoIosSettings } from 'react-icons/io';
import { IoMdTrash } from "react-icons/io";
import { IoMdEye } from "react-icons/io";
import { IoMdDownload } from "react-icons/io";
/*import { IoMdImage } from "react-icons/io";*/

class CodeTypeChain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            note: '',
            confirmChain: false,
            BtnSendText: '',
            showNotes: false,
            showFiles: false,
            files: {},
            selectedFiles: [],
            uploadMessage: '',
            showUploadBtn: false,
            showConfirmModal: false, // Step 1: State variable for confirm modal
            fileToDelete: null,     // Step 1: Track the file to delete
        };

        this.handleInputChange      = this.handleInputChange.bind(this);
        this.handleCheckboxChange   = this.handleCheckboxChange.bind(this);
        this.handleFileChange       = this.handleFileChange.bind(this);
        this.textareaRef            = React.createRef();

    }

    componentDidMount() {
        this.setState({ files: this.props.files });
    };

    handleInputChange(e) {
        const itemName = e.target.name;
        const itemValue = e.target.value;

        this.setState({ [itemName]: itemValue}, () => {
            // Callback function to execute after state has been updated
            this.setBtnSendText();
        });
    }

    handleCheckboxChange(e) {
        const itemName = e.target.id;
        const isChecked = e.target.checked;

        this.setState({ [itemName]: isChecked }, () => {
            // Callback function to execute after state has been updated
            this.setBtnSendText();
        });
    }

    setBtnSendText(){
        if(this.state.note !== '' && this.state.confirmChain === false) {
            this.setState({ btnSendText: "Add Note" });

        } else if(this.state.note !== '' && this.state.confirmChain === true) {
            this.setState({ btnSendText: "Add Note and Confirm Chain" });

        } else if(this.state.note === '' && this.state.confirmChain === true) {
            this.setState({ btnSendText: "Confirm Chain" });
        }

    }

    handleConfirmWrapperClick = () => {
        this.setState((prevState) => ({
            confirmChain: !prevState.confirmChain,
        }), () => {
            // Callback function to execute after state has been updated
            this.setBtnSendText();
        });
    };

    confirmClick(){
        var update            = {};
        update.note           = this.state.note;
        update.confirmChain   = this.state.confirmChain;
        this.props.updateChain(update);
    }

    toogleNotes(){
        if(this.state.showNotes) {
            this.setState({ showNotes: false });
        } else {
            this.setState({ showNotes: true });
        }
    }

    toogleFiles(){
        if(this.state.showFiles) {
            this.setState({ showFiles: false });
        } else {
            this.setState({ showFiles: true });
        }
    }

    handleFileChange(e) {

        this.setState({ uploadMessage: '' });

        const files = e.target.files;
        if (!files || files.length === 0) {
            this.setState({ showUploadBtn: false });
            return;
        }

        this.setState({ showUploadBtn: true });
        this.setState({ selectedFiles: files });
    };

    uploadFiles() {

        if (!this.state.selectedFiles || this.state.selectedFiles.length === 0) {
            this.setState({ uploadMessage: 'Please select at least one file.' });
            return;
        }

        this.setState({ showUploadBtn: false });
        this.setState({ message: 'Uploading files...' });

        const formData = new FormData();

        formData.append('token', this.props.token);
        formData.append('project_id', this.props.projectId);
        formData.append('chain_id', this.props.chain.id);

        for (let i = 0; i < this.state.selectedFiles.length; i++) {
            formData.append('files[]', this.state.selectedFiles[i]);
        }

        this.setState({ step: 1 });

        axios
            .post(this.props.domain + '/core/upload.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {

                console.log(response);

                this.setState({ files: response.data.files });

                // Reset selectedFiles to an empty array after successful upload
                this.setState({
                    selectedFiles: [],
                });

                this.setState({ step: 0 }, () => {
                    // Reset the file input by cloning and replacing it
                    const input = document.querySelector('input[type="file"]');
                    input.value = ''; // Clearing the value directly
                    this.setState({ message: 'Files uploaded successfully!' });
                });

            })
            .catch((error) => {
                console.error('Error sending data:', error);
                this.setState({ message: 'Error uploading files. Please try again.' });
                this.setState({ step: 0 });
            });

    };

    downloadFile = async (file) => {

        try {

            const token      = this.props.token;
            const project_id = this.props.projectId;
            const chain_id   = this.props.chain.id;

            // Make a request to the PHP script
            const response = await fetch(this.props.domain + '/core/download.php?pid=' + project_id + '&cid=' + chain_id + '&file=' + file + '&token=' + token);

            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Get the blob data (the file content)
                const blob = await response.blob();

                // Create a URL for the blob data
                const url = URL.createObjectURL(blob);

                // Create a link element
                const link = document.createElement('a');

                // Set the href attribute to the blob URL
                link.href = url;

                // Set the download attribute to specify the file name
                link.download = 'test.jpg';

                // Append the link to the document
                document.body.appendChild(link);

                // Trigger a click on the link to start the download
                link.click();

                // Remove the link from the document
                document.body.removeChild(link);
            } else {
                console.error('Failed to fetch the file:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error during file download:', error.message);
        }
    };

    toggleConfirmModal = (file = null) => {
        // Step 2: Open or close the confirm modal
        this.setState((prevState) => ({
            showConfirmModal: !prevState.showConfirmModal,
            fileToDelete: file, // Step 2: Set the file to delete
        }));
    };

    deleteFile(file) {
        this.toggleConfirmModal(file);
    }

    confirmDelete = () => {
        // Step 3: Actual deletion logic
        const file = this.state.fileToDelete;

        var data = {
            token: this.props.token,
            project_id: this.props.projectId,
            chain_id: this.props.chain.id,
            file: file,
        };

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=deleteFile',
            data: JSON.stringify(data),
        })
            .then((responseObject) => {
                this.setState({ files: responseObject.data.files });
            })
            .catch((error) => {
                console.log(error);
            });

        // Close the confirm modal after deletion
        this.toggleConfirmModal();
    };





    render () {

        const {colors, chain, notes} = this.props;

        const btnStyle = {
            color: colors.color_2,
            backgroundColor: colors.color_3,
            cursor: 'pointer',
        }

        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
            },
        };

        return (

            <div>
                <div id="description" className="wrapper">
                    <div className="title">{chain.title}</div>
                    <div className="content" dangerouslySetInnerHTML={{ __html: chain.description.replace(/\n/g, '<br>') }}></div>
                </div>

                <div id="due" className="wrapper">
                    <div className="title">Due Date</div>
                    <div className="content">
                        {chain.due_date}
                    </div>
                </div>

                {Object.keys(this.state.files).length > 0 &&
                <div id="files" className="wrapper">
                    <div className="title" onClick={() => this.toogleFiles()}>Files: [{Object.keys(this.state.files).length}]</div>
                    {this.state.showFiles &&
                    <div id="file-list" className="container-fluid">

                        <PhotoProvider>

                            {Object.entries(this.state.files).map(([key, value]) => (
                                <div className="row" key={key}>
                                    <div className="name col-5">{value.markup}</div>
                                    <div className="btn-wrapper col-7">
                                        <span className="delete" onClick={() => this.deleteFile(value.file)}><IoMdTrash/></span>
                                        <span className="download" onClick={() => this.downloadFile(value.file)}><IoMdDownload/></span>
                                        {(value.extention === 'jpg' || value.extention === 'jpeg' || value.extention === 'png') &&
                                            <PhotoView key={key} src={this.props.domain + '/file.php?pid=' + this.props.projectId + '&cid=' + this.props.chain.id + '&file=' + value.file}>
                                                <span className="show"><IoMdEye/></span>
                                            </PhotoView>
                                        }
                                    </div>

                                </div>
                            ))}

                        </PhotoProvider>

                    </div>
                    }

                    <Modal
                        isOpen={this.state.showConfirmModal}
                        onRequestClose={this.toggleConfirmModal}
                        contentLabel="Confirm Modal"
                        style={customStyles}
                        >
                        <div>
                            <p>Are you sure you want to delete this file?</p>
                            <button onClick={this.confirmDelete} className="btn-medium-delete" style={btnStyle}>Delete</button>
                            <button onClick={this.toggleConfirmModal} className="btn-medium-cancel" style={btnStyle}>No</button>
                        </div>
                    </Modal>

                </div>
                }

                {chain.required_images > 0 &&
                    <div id="upload" className="wrapper">
                        <div className="title">Upload</div>
                        <input
                            type="file"
                            onChange={this.handleFileChange}
                            multiple // Enable multiple file selection
                            accept=".jpg, .jpeg, .png, .pdf, .docx"
                            />
                        <div>{this.state.uploadMessage}</div>
                        {this.state.showUploadBtn &&
                            <button className="btn-medium mt-3" style={btnStyle} onClick={() => this.uploadFiles()}>Upload Files</button>
                        }

                    </div>
                }

                {notes.length > 0 &&
                    <div id="notes" className="wrapper">
                        <div className="title" onClick={() => this.toogleNotes()}>Notes: [{notes.length}]</div>
                        {this.state.showNotes &&
                            <ul id="note-list">
                                {notes.map((item, index) => (
                                    <li key={index}><div className="note-date">{item.created}</div><div>{item.note}</div></li>
                                ))}
                            </ul>
                        }

                    </div>
                }

                <div id="add-note" className="mb-3">
                    <div className="title">Add Note</div>
                        <textarea
                            className={"form-control pl-3"}
                            rows="5"
                            id="note"
                            name="note"
                            placeholder="note"
                            defaultValue=""
                            onChange={this.handleInputChange}
                            ref={this.textareaRef}
                            />
                </div>

                <div id="confirm-wrapper" className="" onClick={this.handleConfirmWrapperClick}>
                    <input
                        type        = "checkbox"
                        id          = "confirmChain"
                        checked     ={this.state.confirmChain}
                        onChange    ={this.handleCheckboxChange}
                        /><span> Confirm Chain</span>
                </div>

                { (this.state.note !== '' || this.state.confirmChain === true) &&
                    <div className="text-center m2">
                    <button className="btn-medium" type="button" style={btnStyle}
                            onClick={() => this.confirmClick()}>
                        { this.state.btnSendText } <IoIosSettings/>
                    </button>
                    </div>
                }


            </div>

        )
    }
}

export default CodeTypeChain;